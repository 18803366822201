
import { Menus } from '@rtl/api'
import Headroom from 'headroom.js'
import { mixins, Options, Ref, Watch } from 'vue-property-decorator'
import { Action } from 'vuex-class'

import { Response } from '../../gigya'
import { ROUTES } from '../../router'
import { USER_ACTION, USER_NAMESPACE } from '../../store'
import DarkMode from '../components/icons/DarkMode.vue'
import FacebookFull from '../components/icons/FacebookFull.vue'
import Home from '../components/icons/Home.vue'
import Instagram from '../components/icons/Instagram.vue'
import MainLogo from '../components/icons/MainLogo.vue'
import Programs from '../components/icons/Programs.vue'
import RtlNow from '../components/icons/RtlNow.vue'
import Search from '../components/icons/Search.vue'
import TagX from '../components/icons/TagX.vue'
import Twitter from '../components/icons/Twitter.vue'
import User from '../components/icons/User.vue'
import Weather from '../components/icons/Weather.vue'
import NavLink from '../components/NavLink.vue'
import SocialMediaLinks from '../components/SocialMediaLinks.vue'
import SliderLeft from '../components/icons/SliderLeft.vue'
import VoteBanner from '../components/marketing/VoteBanner.vue'
import { GetPageMixin, GetUserMixin } from '../mixins'
import BannerWidget from '../widgets/BannerWidget.vue'
import { platform, Platform } from './../../utils'

@Options({
  name: 'MainHeader',
  components: {
    MainLogo,
    NavLink,
    SocialMediaLinks,
    Weather,
    Home,
    Programs,
    DarkMode,
    Search,
    RtlNow,
    FacebookFull,
    Instagram,
    Twitter,
    User,
    TagX,
    VoteBanner,
    SliderLeft,
    BannerWidget
  }
})
export default class MainHeader extends mixins(GetPageMixin, GetUserMixin) {
  @Action(USER_ACTION.LOGOUT, { namespace: USER_NAMESPACE })
  logoutUserAction!: () => Promise<Response>

  private isNavOpen = false
  private scrollPosition = 0
  private lastScrollPos = 0
  private searchText = null
  private platform: Platform | null = null

  isElectionMenu (menu: string) {
    return menu === '/cimke/valasztas2022' || menu === '/cimke/valasztas-2022'
  }

  @Ref('header')
  readonly header!: HTMLElement

  logout () {
    this.logoutUserAction().then(() => {
      this.$router.push({ name: 'login' })
    })
  }

  async mounted () {
    this.platform = platform()

    const ion = (this.$root?.$refs?.ionContent as any)?.$el
    const scroller = await ion?.getScrollElement()
    const headroom = new Headroom(this.header, {
      offset: 400,
      tolerance: 0,
      onPin () {
        headroom.freeze()
        setTimeout(() => {
          headroom.unfreeze()
        }, 1000)
      },
      onUnpin () {
        headroom.freeze()
        setTimeout(() => {
          headroom.unfreeze()
        }, 1000)
      },
      ...(scroller ? { scroller } : {}) // Use the virtual scroller on Ionic, otherwise run with defaults
    })
    if (this.header) {
      headroom.init()
    }
  }

  get menus (): Menus {
    return this.page?.menu || {}
  }

  toggleMenu (val?: boolean) {
    this.isNavOpen = val !== undefined ? val : !this.isNavOpen
    if (this.isNavOpen) {
      this.measure('Hamburger')
    }
  }

  toggleMobileMenu (val?: boolean) {
    // todo: measure?
    this.isNavOpen = val !== undefined ? val : !this.isNavOpen
    this.scrollPosition = window.pageYOffset
    const html = document.querySelector('html')
    const htmlElement = html as HTMLHtmlElement
    const body = document.querySelector('body')
    const bodyElement = body as HTMLBodyElement

    if (this.isNavOpen) {
      bodyElement.classList.add('menu-open')
      this.lastScrollPos = this.scrollPosition
      htmlElement.style.position = 'fixed'
      htmlElement.style.top = `-${this.scrollPosition}px`
      htmlElement.style.width = '100%'
      this.measure('Hamburger')
    } else {
      bodyElement.classList.remove('menu-open')
      htmlElement.style.removeProperty('position')
      htmlElement.style.removeProperty('top')
      htmlElement.style.removeProperty('width')
      window.scrollTo(0, this.lastScrollPos)
    }
  }

  @Watch('$route.fullPath')
  onRouteChange () {
    this.toggleMobileMenu(false)
    this.toggleMenu(false)
    window.scrollTo(0, 0)
  }

  goBack() {
   ((this as any).$routeReferer) ? this.$router.back() : this.$router.push('/')
  }

  get switchActive (): boolean {
    const routeName = this.$route?.name
    return routeName !== ROUTES.INDEX && routeName !== ROUTES.SHOW_INDEX
  }

  get navBackVisible (): boolean {
    return this.$route?.name !== ROUTES.INDEX && !!this.platform && this.platform !== 'web'
  }

  search () {
    if (this.searchText) {
      this.$router.push({ name: ROUTES.SEARCH, query: { q: this.searchText } })
    }
  }

  measure (name: string) {
    if (this.$gtm && this.$gtm.enabled()) {
      this.$gtm.trackEvent({
        event: 'interaction',
        event_name: 'menu_click',
        item_name: name
      })
    }
  }
}
