

import { Model, Options, Prop, Vue } from 'vue-property-decorator'

import Exclamation from './icons/Exclamation.vue'

@Options({
  name: 'Radiobutton',
  components: {
    Exclamation
  }
})
export default class Radiobutton extends Vue {
  @Model('modelValue', { type: String, default: '' })
  readonly value!: string

  @Prop({ type: String, required: true })
  readonly modelName!: string

  @Prop({ type: String, required: true })
  readonly text1!: string

  @Prop({ type: String, required: true })
  readonly text2!: string

  @Prop({ type: String, required: true })
  readonly value1!: string

  @Prop({ type: String, required: true })
  readonly value2!: string

  @Prop({ type: String, required: false })
  readonly label!: string

  @Prop({ type: String, default: null })
  readonly errorMsg!: string | null
}
